
#id6735268ae110d14092e922b7 {
  #iw4j {
min-height : 100px; 
font-family : Montserrat; 
} 
#ips2 {
padding : 10px 5% 10px 5%; 
min-height : 100px; 
display : flex; 
justify-content : space-between; 
border-radius : 0 0 0 62px; 
position : relative; 
margin : 0px -78px 0px -60px; 
} 
#i3dh {
padding : 0px 5% 0px 5%; 
flex-direction : column; 
display : flex; 
align-items : center; 
font-family : "Lexend", serif; 
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j8m8vd.jpg'); 
} 
#itmdc {
padding : 10px; 
min-height : 100px; 
width : 783px; 
display : flex; 
flex-wrap : wrap; 
align-self : center; 
align-items : center; 
justify-content : space-around; 
} 
#ismeq {
color : black; 
display : flex; 
align-self : center; 
left : -6px; 
} 
#i2g1w {
width : auto; 
text-decoration : none; 
color : #ebebeb; 
font-size : 20px; 
} 
#i2aqf {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
font-size : 22px; 
} 
#ib1qi {
width : auto; 
color : #ebebeb; 
font-size : 20px; 
} 
#iwu1w {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
font-size : 22px; 
} 
#ipu5h {
width : auto; 
color : #ebebeb; 
padding : 0px 0px 0px 0px; 
font-size : 20px; 
} 
#i31ek {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
font-size : 22px; 
} 
#i93l3 {
padding : 10px; 
display : block; 
font-size : 25px; 
text-align : left; 
color : #0072ed; 
width : 100%; 
} 
#ihp5j {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : center; 
margin : 0px 0px 0px 0px; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#ijh55q {
padding : 10px; 
display : block; 
font-size : 32px; 
font-weight : 600; 
text-align : left; 
margin : 20px 0px 20px 0px; 
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j8zxo1.jpg'); 
border-radius : 20px 20px 20px 20px; 
width : 100%; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#icq3pj {
display : none; 
} 
#it2j54 {
padding : 10px 0px 10px 0px; 
min-height : 730px; 
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j6dm3e.jpg'); 
} 
#ihjr3o {
padding : 10px 15px 10px 20px; 
min-height : 577px; 
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
align-items : flex-start; 
color : black; 
} 
#iizaeu {
padding : 10px; 
display : block; 
color : #ffd700; 
font-size : 70px; 
width : 629px; 
font-family : "Lexend", serif; 
font-weight : 800; 
} 
#iyinfc {
padding : 10px; 
display : block; 
font-size : 28px; 
width : 593px; 
font-family : "Lexend", serif; 
font-weight : 300; 
color : #213a7c; 
} 
#ijpl2e {
white-space-collapse : preserve; 
font-family : "Lexend", serif; 
font-size : 60px; 
color : #213a7c; 
} 
#ifxeud {
min-height : 100px; 
} 
.links {
font-size : 18px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#213a7c 0%, #213a7c 100%); 
border-radius : 20px 20px 20px 20px; 
} 
.links:hover  {
color : #2e709e; 
} 
#i2g1w:hover  {
color : #2e709e; 
text-decoration : none; 
} 
#ib1qi:hover  {
color : #2e709e; 
} 
#ipu5h:hover  {
color : #2e709e; 
} 
.bloque_logos {
width : 130px; 
height : 45.1px; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37nhhbw.png'); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 32px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
__background-type : image; 
background-repeat : no-repeat; 
background-size : contain; 
background-position : center center; 
background-attachment : scroll; 
min-height : 86auto; 
width : 301px; 
height : 78px; 
align-self : center; 
border-radius : 0px 0px 0 0px; 
border : 0 none black; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#igaurm {
white-space-collapse : preserve; 
margin : 0px 0px 0px 30px; 
font-family : "Lexend", serif; 
font-weight : 800; 
color : #213a7c; 
} 
#itg24k {
white-space-collapse : preserve; 
width : 10auto; 
font-family : "Lexend", serif; 
font-size : 25px; 
color : #213a7c; 
} 
#igiten {
color : black; 
width : 339px; 
height : 220px; 
} 
#ic3u6r {
padding : 10px; 
min-height : 100px; 
width : 380px; 
display : flex; 
justify-content : center; 
} 
#iv0g0l {
padding : 10px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37oczi9.png'); 
} 
#itedql {
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37oj9p6.png'); 
width : 623px; 
height : 463px; 
display : flex; 
position : absolute; 
color : #ffffff; 
align-items : center; 
top : 3%; 
right : 52%; 
} 
#iozxpl {
padding : 10px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : none; 
width : 1034px; 
height : 603px; 
position : absolute; 
display : flex; 
align-items : flex-start; 
left : 48%; 
min-height : 11px; 
} 
#ivyo6u {
padding : 10px; 
width : 623px; 
color : #ffffff; 
min-height : 463px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f6b607 0%, #f6b607 100%); 
border-radius : 34px 34px 34px 34px; 
font-family : "Lexend", serif; 
} 
#i010iv {
color : black; 
position : relative; 
top : 55%; 
right : -33%; 
width : 623.76953125px; 
margin : -12% 0px 1px 0px; 
} 
.gjs-dropdown-menu-container {
position : relative; 
display : block; 
} 
.gjs-dropdown-menu-trigger {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
color : #ffffff; 
cursor : pointer; 
} 
.gjs-dropdown-menu-content {
position : absolute; 
width : 100%; 
min-height : 200px; 
box-shadow : rgba(0, 0, 0, 0.2) 0px 8px 16px 0px; 
z-index : 5; 
} 
.inputContainer {
width : 603px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
color : #ffffff; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-position-x : initial; 
background-position-y : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
color : #ffffff; 
} 
.standard_button.modificado {
__background-type : color; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : linear-gradient(#213a7c 0%, #213a7c 100%); 
width : 120px; 
height : 40px; 
border-radius : 15px 15px 15px 15px; 
} 
#i83rj4 {
width : 100%; 
display : flex; 
flex-direction : column; 
margin : 5% 0px 4% 0px; 
align-self : stretch; 
align-items : flex-end; 
} 
#ilgjxb {
padding : 10px; 
min-height : 100px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j9b6hx.jpg'); 
display : flex; 
justify-content : flex-end; 
align-items : center; 
} 
#ipjst4 {
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#213a7c 0%, #213a7c 100%); 
} 
#in4gsc {
padding : 10px; 
min-height : 100px; 
width : 926px; 
display : flex; 
justify-content : space-between; 
align-items : center; 
} 
#iy0bu7 {
padding : 10px; 
display : block; 
color : #ffffff; 
min-height : 10auto; 
width : 568.438px; 
text-align : center; 
font-size : 32px; 
} 
#i137py {
color : black; 
width : 38px; 
height : 44px; 
} 
#i6s1k4 {
white-space-collapse : preserve; 
font-family : "Lexend", serif; 
font-weight : 500; 
} 
#iuyhhs {
padding : 10px; 
display : block; 
font-size : 20px; 
} 
#i987ss {
white-space-collapse : preserve; 
color : #ffffff; 
font-family : "Lexend", serif; 
} 
#i68lj2 {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 
#iru1nq {
color : black; 
width : 38px; 
height : 44px; 
} 
#iqu7o1 {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 
#ix8eqg {
color : black; 
width : 38px; 
height : 44px; 
} 
#i6irgm {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 
#i64dbt {
width : auto; 
text-decoration : none; 
} 
#i64dbt:hover  {
color : #2e709e; 
text-decoration : none; 
} 
#ibclo5 {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
font-size : 22px; 
} 
#i1xreu {
width : auto; 
} 
#i1xreu:hover  {
color : #2e709e; 
} 
#i28qdz {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
font-size : 22px; 
} 
#i0vg2v {
width : auto; 
} 
#i0vg2v:hover  {
color : #2e709e; 
} 
#idayty {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
font-size : 22px; 
} 
#io767h {
padding : 10px; 
min-height : 100px; 
width : 783px; 
display : none; 
flex-wrap : wrap; 
align-self : center; 
align-items : center; 
justify-content : space-around; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37lj913.png'); 
} 
#itdh6f {
white-space-collapse : preserve; 
} 
#iqj6i5 {
padding : 10px; 
display : block; 
color : #ffffff; 
min-height : 10auto; 
width : 568.438px; 
text-align : center; 
font-size : 32px; 
} 
#irya7h {
color : black; 
width : 38px; 
height : 44px; 
} 
#i9g4xj {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 
#i7gf77 {
color : black; 
width : 38px; 
height : 44px; 
} 
#i7qfbt {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 
#i9veu5 {
color : black; 
width : 38px; 
height : 44px; 
} 
#i1dymt {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 
#iklg6x {
width : 20.09%; 
min-height : 66px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#is16l4 {
padding : 10px; 
min-height : 100px; 
width : 926px; 
display : flex; 
justify-content : space-between; 
align-items : center; 
} 
#imqdmc {
min-height : 100px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37q2ixa.png'); 
display : none; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
#iwt1hr {
min-height : 100px; 
padding : 10px; 
} 
#i29u1z {
min-height : 100px; 
width : 783px; 
display : flex; 
justify-content : center; 
} 
#idiuka {
width : 183.1015625px; 
align-self : center; 
min-height : 10auto; 
} 
#i3sgq6 {
color : black; 
width : 339px; 
height : 220px; 
} 
#ily5z4 {
padding : 10px; 
min-height : 100px; 
width : 380px; 
display : flex; 
justify-content : center; 
} 
#ieb0gi {
color : black; 
width : 339px; 
height : 220px; 
} 
#i08g4i {
padding : 10px; 
min-height : 100px; 
width : 380px; 
display : flex; 
justify-content : center; 
} 
.standard_button.cotizaciones {
display : none; 
} 
#ilijmz {
padding : 10px; 
display : flex; 
width : 225px; 
justify-content : space-around; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.imgDynamics {
width : 100px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#iusqje {
min-height : 100px; 
} 
#iqbpt8 {
padding : 0px 10px 0px 10px; 
color : #ffffff; 
} 
#ii6atx {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i5wlak {
background-color : transparent; 
} 
#ir6yms {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i4mwul {
background-color : transparent; 
} 
#i4vxf5 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#il6dm3 {
background-color : transparent; 
} 
#i3tb8i {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i1aexa {
background-color : transparent; 
} 
#ix08y7 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#ioq1a4 {
background-color : transparent; 
} 
#i91k7e {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i7c7rq {
background-color : transparent; 
} 
#iyflrx {
font-size : 18px; 
} 
#ihth4p {
min-height : 100px; 
display : flex; 
} 
#ics90s {
padding : 10px 10px 10px 0px; 
min-height : 100px; 
width : 50%; 
} 
#il8713 {
padding : 10px 0px 10px 10px; 
min-height : 100px; 
width : 50%; 
} 
#i4vebl {
padding : 10px 10px 10px 0px; 
min-height : 100px; 
width : 50%; 
} 
#ihaobl {
padding : 10px 0px 10px 10px; 
min-height : 100px; 
width : 50%; 
} 
#ie8nca {
min-height : 100px; 
display : flex; 
} 
.input {
width : 100%; 
color : #000000; 
height : 45px; 
padding : 10px 10px 10px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 15px 15px 15px 15px; 
} 
#izq2u3 {
color : black; 
width : 38px; 
height : 44px; 
} 
#i2gwnh {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 
#i54ol1 {
color : black; 
width : 38px; 
height : 44px; 
} 
#i9zju9 {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 

  @media only screen and (max-width: 992px) {#ips2 {
padding : 10px 5px 10px 5px; 
left : 0px; 
}} 
@media only screen and (max-width: 992px) {#ic3u6r {
width : 320px; 
}} 
@media only screen and (max-width: 992px) {#iizaeu {
font-size : 32px; 
width : 100%; 
display : none; 
min-height : 10auto; 
align-items : stretch; 
justify-content : center; 
}} 
@media only screen and (max-width: 992px) {#iyinfc {
font-size : 16px; 
width : 100%; 
justify-content : center; 
display : none; 
}} 
@media only screen and (max-width: 992px) {#itmdc {
width : 362px; 
min-height : 40px; 
display : none; 
}} 
@media only screen and (max-width: 992px) {.links {
font-size : 12px; 
width : 127px; 
border-radius : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#ipu5h {
font-size : 12px; 
}} 
@media only screen and (max-width: 992px) {#i2g1w {
font-size : 12px; 
}} 
@media only screen and (max-width: 992px) {#ib1qi {
font-size : 12px; 
}} 
@media only screen and (max-width: 992px) {#ismeq {
left : -12px; 
width : 145.38325px; 
min-height : 10px; 
}} 
@media only screen and (max-width: 992px) {#it2j54 {
position : relative; 
min-height : 704px; 
__background-type : image; 
background-repeat : repeat; 
background-position : center center; 
background-attachment : fixed; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j6dm3e.jpg'); 
}} 
@media only screen and (max-width: 992px) {#i64dbt {
font-size : 12px; 
color : #ebebeb; 
}} 
@media only screen and (max-width: 992px) {#i1xreu {
font-size : 12px; 
color : #ebebeb; 
}} 
@media only screen and (max-width: 992px) {#i0vg2v {
font-size : 12px; 
color : #ebebeb; 
}} 
@media only screen and (max-width: 992px) {#io767h {
width : 362px; 
min-height : 40px; 
display : flex; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : none; 
font-family : "Lexend", serif; 
}} 
@media only screen and (max-width: 992px) {.card {
width : 285px; 
height : 380px; 
min-height : 10px; 
flex-direction : column; 
}} 
@media only screen and (max-width: 992px) {#in4gsc {
width : 760px; 
}} 
@media only screen and (max-width: 992px) {#ihp5j {
width : 712px; 
}} 
@media only screen and (max-width: 992px) {#iozxpl {
height : 500px; 
position : relative; 
left : auto; 
width : 100%; 
min-height : 677px; 
}} 
@media only screen and (max-width: 992px) {#i010iv {
width : 657.9296875px; 
top : 74%; 
right : -31%; 
height : auto; 
min-height : 173.34375auto; 
margin : -26% 0px 1px -28%; 
}} 
@media only screen and (max-width: 992px) {#itedql {
width : 90%; 
min-height : 143px; 
right : 7%; 
border-radius : 10px 10px 10px 10px; 
top : 2%; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : none; 
}} 
@media only screen and (max-width: 992px) {.formInput {
width : 331px; 
}} 
@media only screen and (max-width: 992px) {.gjs-dropdown-menu-container {
width : 343px; 
min-height : 10px; 
height : 15%; 
display : flex; 
justify-content : flex-start; 
align-items : center; 
}} 
@media only screen and (max-width: 992px) {.inputContainer {
width : 100%; 
height : 15%; 
}} 
@media only screen and (max-width: 992px) {#ivyo6u {
font-size : 12px; 
min-height : 296px; 
display : block; 
width : 100%; 
}} 
@media only screen and (max-width: 992px) {.standard_button.modificado {
width : 119px; 
height : 36px; 
}} 
@media only screen and (max-width: 992px) {#i83rj4 {
min-height : 10px; 
}} 
@media only screen and (max-width: 992px) {#iy0bu7 {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {#ipjst4 {
width : 760px; 
display : none; 
}} 
@media only screen and (max-width: 992px) {#iqj6i5 {
font-size : 20px; 
width : 309.438px; 
}} 
@media only screen and (max-width: 992px) {#is16l4 {
width : 586px; 
font-family : "Lexend", serif; 
}} 
@media only screen and (max-width: 992px) {#imqdmc {
width : 760px; 
display : block; 
__background-type : color; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#213a7c 0%, #213a7c 100%); 
}} 
@media only screen and (max-width: 992px) {#iklg6x {
width : 171.062px; 
}} 
@media only screen and (max-width: 992px) {#iuyhhs {
font-size : 13px; 
}} 
@media only screen and (max-width: 992px) {#irya7h {
width : 25px; 
height : 29px; 
}} 
@media only screen and (max-width: 992px) {#i9g4xj {
width : 25px; 
height : 29px; 
}} 
@media only screen and (max-width: 992px) {#i7gf77 {
width : 25px; 
height : 29px; 
}} 
@media only screen and (max-width: 992px) {#i7qfbt {
height : 29px; 
width : 25px; 
}} 
@media only screen and (max-width: 992px) {#i9veu5 {
width : 25px; 
height : 29px; 
}} 
@media only screen and (max-width: 992px) {#i1dymt {
width : 25px; 
height : 29px; 
}} 
@media only screen and (max-width: 992px) {#ily5z4 {
width : 320px; 
display : none; 
}} 
@media only screen and (max-width: 992px) {#i08g4i {
width : 320px; 
}} 
@media only screen and (max-width: 992px) {#ijh55q {
width : 684.5455322265625px; 
font-size : 26px; 
}} 
@media only screen and (max-width: 992px) {#i29u1z {
justify-content : flex-end; 
width : 362px; 
margin : 0px 11% 0px 0px; 
}} 
@media only screen and (max-width: 992px) {#idiuka {
left : 12%; 
position : relative; 
}} 
@media only screen and (max-width: 992px) {#ihjr3o {
min-height : 504px; 
display : block; 
padding : 10px 0px 10px 20px; 
}} 
@media only screen and (max-width: 992px) {#ijpl2e {
width : 324auto; 
text-align : center; 
}} 
@media only screen and (max-width: 992px) {#iwt1hr {
min-height : 168px; 
padding : 10px 0px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#i3dh {
padding : 76px 5% 0px 5%; 
}} 
@media only screen and (max-width: 992px) {#itg24k {
width : 10auto; 
font-size : 20px; 
Altura-de-salto-de-linea : -4px; 
}} 
@media only screen and (max-width: 992px) {#i93l3 {
width : 665.7734375px; 
Altura-de-salto-de-linea : 0px; 
}} 
@media only screen and (max-width: 992px) {#i54ol1 {
width : 25px; 
height : 29px; 
}} 
@media only screen and (max-width: 992px) {#i9zju9 {
width : 25px; 
height : 29px; 
}} 

  @media only screen and (max-width: 480px) {#itmdc {
display : none; 
}} 
@media only screen and (max-width: 480px) {#icq3pj {
padding : 10px; 
min-height : 100px; 
width : auto; 
display : flex; 
align-items : flex-end; 
justify-content : center; 
position : relative; 
left : -28px; 
bottom : 12px; 
}} 
@media only screen and (max-width: 480px) {#ijh55q {
font-size : 10px; 
width : 278.797px; 
padding : 10px 10px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#i93l3 {
font-size : 12px; 
width : 295.4609375px; 
}} 
@media only screen and (max-width: 480px) {#io767h {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iozxpl {
height : 555px; 
display : block; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#i010iv {
top : 81%; 
right : -26%; 
}} 
@media only screen and (max-width: 480px) {#itedql {
width : 100%; 
top : 0%; 
right : 0%; 
}} 
@media only screen and (max-width: 480px) {#is16l4 {
width : 100%; 
height : 100%; 
min-height : 100%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#213a7c 0%, #213a7c 100%); 
}} 
@media only screen and (max-width: 480px) {#imqdmc {
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37szznd.png'); 
min-height : 10px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {.gjs-dropdown-menu-container {
width : 297px; 
}} 
@media only screen and (max-width: 480px) {.inputContainer {
width : 295px; 
}} 
@media only screen and (max-width: 480px) {.formInput {
width : 288px; 
}} 
@media only screen and (max-width: 480px) {#iqj6i5 {
font-size : 11px; 
width : 178.703px; 
}} 
@media only screen and (max-width: 480px) {#ic3u6r {
width : 242px; 
height : 178px; 
justify-content : center; 
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#igiten {
width : 274px; 
height : 178px; 
}} 
@media only screen and (max-width: 480px) {#ihp5j {
width : 310px; 
}} 
@media only screen and (max-width: 480px) {.standard_button {
width : 200px; 
height : 50px; 
font-size : 15px; 
}} 
@media only screen and (max-width: 480px) {#ijpl2e {
width : 10auto; 
text-align : left; 
}} 
@media only screen and (max-width: 480px) {#iyinfc {
width : 255px; 
font-size : 8px; 
justify-content : flex-start; 
}} 
@media only screen and (max-width: 480px) {#iizaeu {
width : 256px; 
font-size : 20px; 
justify-content : flex-start; 
}} 
@media only screen and (max-width: 480px) {.contentwrapperanimated {
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#iklg6x {
min-height : 20px; 
}} 
@media only screen and (max-width: 480px) {#iuyhhs {
font-size : 11px; 
width : 203.21875px; 
text-align : right; 
}} 
@media only screen and (max-width: 480px) {#i3sgq6 {
width : 274px; 
height : 178px; 
}} 
@media only screen and (max-width: 480px) {#ily5z4 {
width : 242px; 
height : 178px; 
justify-content : center; 
align-items : center; 
display : none; 
}} 
@media only screen and (max-width: 480px) {#ieb0gi {
width : 274px; 
height : 178px; 
}} 
@media only screen and (max-width: 480px) {#i08g4i {
width : 242px; 
height : 178px; 
justify-content : center; 
align-items : center; 
display : none; 
}} 
@media only screen and (max-width: 480px) {#i29u1z {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iwt1hr {
min-height : 227px; 
padding : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#ihjr3o {
align-items : flex-start; 
justify-content : flex-end; 
min-height : 226px; 
align-self : flex-start; 
padding : 10px 10px 10px 10px; 
flex-direction : column; 
}} 
@media only screen and (max-width: 480px) {#it2j54 {
min-height : 435.875px; 
}} 
@media only screen and (max-width: 480px) {.standard_button.cotizaciones {
align-self : auto; 
display : block; 
}} 
@media only screen and (max-width: 480px) {#ilgjxb {
__background-type : image; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j9b6hx.jpg'); 
}} 
@media only screen and (max-width: 480px) {#i987ss {
width : 10auto; 
}} 
@media only screen and (max-width: 480px) {#itg24k {
width : 10auto; 
font-size : 12px; 
}} 
@media only screen and (max-width: 480px) {#i3dh {
min-height : 10px; 
}} 
@media only screen and (max-width: 480px) {#ismeq {
width : 101.69159375000001px; 
min-height : 10px; 
left : -10px; 
}} 
@media only screen and (max-width: 480px) {#idiuka {
left : 16%; 
width : auto; 
}} 
@media only screen and (max-width: 480px) {.menu__close__btn {
display : flex; 
}} 
@media only screen and (max-width: 480px) {#ips2 {
width : 100%; 
position : relative; 
margin : 0px 0px 0px 0px; 
border-radius : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#i4vebl {
width : 100%; 
padding : 0px 0px 0px 0px; 
min-height : auto; 
}} 
@media only screen and (max-width: 480px) {#ie8nca {
flex-wrap : wrap; 
}} 
@media only screen and (max-width: 480px) {#i4vxf5 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ihaobl {
width : 100%; 
padding : 0px 0px 0px 0px; 
min-height : auto; 
}} 
@media only screen and (max-width: 480px) {#ihth4p {
flex-wrap : wrap; 
}} 

}
  