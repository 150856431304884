
#id672cf18ee110d14092e8cac7 {
  #iw4j {
min-height : 100px; 
font-family : Montserrat; 
} 
#ips2 {
padding : 7px 1% 10px 2%; 
min-height : 10px; 
display : flex; 
justify-content : space-between; 
align-items : center; 
} 
#i6xx {
padding : 80px 5% 20px 5%; 
min-height : 55px; 
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j8m8vd.jpg'); 
font-weight : 800; 
font-family : "Lexend", serif; 
} 
#i3dh {
padding : 0px 5% 0px 5%; 
flex-direction : column; 
display : flex; 
align-items : center; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
background-image : linear-gradient(#ebebeb 0%, #ebebeb 100%); 
font-family : "Lexend", serif; 
font-weight : 300; 
} 
#itmdc {
padding : 10px; 
min-height : 34.75390625px; 
width : 1402.546875px; 
display : flex; 
flex-wrap : wrap; 
align-self : center; 
align-items : center; 
justify-content : space-around; 
border-radius : 50px 50px 50px 50px; 
} 
#ismeq {
color : black; 
width : 1843px; 
display : flex; 
align-self : center; 
float : none; 
top : 0px; 
min-height : 58.32421875px; 
} 
#i2g1w {
width : auto; 
text-decoration : none; 
border : 0px solid #ebebeb; 
color : #ebebeb; 
font-size : 20px; 
} 
#i2aqf {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
font-size : 22px; 
} 
#ib1qi {
width : auto; 
color : #ebebeb; 
font-size : 20px; 
} 
#iwu1w {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
font-size : 22px; 
} 
#ipu5h {
width : auto; 
color : #ebebeb; 
font-size : 20px; 
} 
#i31ek {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
font-size : 22px; 
} 
#igqyi {
padding : 10px; 
font-size : 35px; 
font-weight : 800; 
text-align : left; 
width : 566.38015625px; 
align-self : center; 
left : 2%; 
position : relative; 
color : #213a7c; 
font-family : "Lexend", serif; 
} 
#i93l3 {
padding : 10px; 
display : block; 
font-size : 25px; 
text-align : left; 
color : #213a7c; 
} 
#ihp5j {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : center; 
margin : 0px 0px 0px 0px; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#icq3pj {
display : none; 
} 
#it2j54 {
padding : 10px; 
min-height : 730px; 
__background-type : image; 
background-repeat : space; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j6dm3e.jpg'); 
overflow : hidden; 
} 
#ihjr3o {
padding : 10px 0px 10px 20px; 
min-height : 577px; 
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
align-items : flex-start; 
overflow : hidden; 
} 
#iizaeu {
padding : 10px; 
display : block; 
color : #213a7c; 
font-size : 57px; 
width : 36%; 
font-family : "Lexend", serif; 
font-weight : 700; 
float : center; 
text-align : left; 
letter-spacing : 0px; 
} 
#iyinfc {
padding : 10px; 
display : block; 
font-size : 28px; 
color : #213a7c; 
width : 36.01%; 
font-family : "Lexend", serif; 
font-weight : 400; 
} 
#ijpl2e {
white-space-collapse : preserve; 
float : center; 
align-items : flex-start; 
justify-content : space-around; 
text-align : left; 
font-weight : 800; 
} 
#ifxeud {
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ebebeb 0%, #ebebeb 100%); 
} 
.links {
font-size : 18px; 
border : 0px solid #ebebeb; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#213a7c 0%, #213a7c 100%); 
border-radius : 20px 20px 20px 20px; 
width : 118.48043125px; 
min-height : 10auto; 
} 
.links:hover  {
color : #2e709e; 
} 
#i2g1w:hover  {
color : #2e709e; 
text-decoration : none; 
} 
#ib1qi:hover  {
color : #2e709e; 
} 
#ipu5h:hover  {
color : #2e709e; 
} 
#i4j8vo {
padding : 0px 0px 0px 0px; 
display : flex; 
flex-direction : column; 
min-height : 10px; 
} 
.bloque_logos {
width : 130px; 
height : 45.1px; 
} 
#icxabj {
padding : 10px 10px 10px 0; 
min-height : 232px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j7634m.jpg'); 
} 
#itgbub {
padding : 10px; 
min-height : 215px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left center; 
background-attachment : scroll; 
background-size : auto; 
background-image : none; 
display : flex; 
margin : 0px 0px 0px 0px; 
width : 1827.94921875px; 
justify-content : flex-start; 
align-items : center; 
align-self : auto; 
font-family : "Lexend", serif; 
font-weight : 300; 
} 
#i4n5wk {
padding : 10px; 
min-height : 100px; 
width : 590.47265625px; 
align-items : center; 
color : #fffdfd; 
} 
#i4n5wk {
padding : 10px; 
min-height : 100px; 
display : flex; 
align-items : center; 
margin : 0px 0 0 0; 
} 
#iir65o {
padding : 10px; 
min-height : 83px; 
display : flex; 
justify-content : space-between; 
margin : 0 0 1% 0; 
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j8zxo1.jpg'); 
border-radius : 30px 30px 30px 30px; 
font-weight : 800; 
font-family : "Lexend", serif; 
} 
#ixg0cs {
color : #d983a6; 
padding : 5px; 
width : 110px; 
height : 37px; 
min-height : 10auto; 
display : flex; 
align-self : center; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#213a7c 0%, #213a7c 100%); 
border-radius : 10px 10px 10px 10px; 
margin : 0px 0px 0px 0px; 
} 
#iselx6 {
width : auto; 
color : #ebebeb; 
margin : 2px 2px 0px 6px; 
} 
#iselx6:hover  {
color : #0072ed; 
} 
#ixg0cs:hover  {
color : #0072ed; 
} 
#iiipyl {
height : 24px; 
display : flex; 
align-self : center; 
width : 24px; 
min-height : 14auto; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37n4mt8.png'); 
} 
#iuu732 {
padding : 10px; 
min-height : 22px; 
width : 182px; 
display : flex; 
justify-content : center; 
} 
#i5gjog {
min-height : 428px; 
display : flex; 
flex-direction : column; 
justify-content : center; 
} 
#isixvu {
padding : 10px; 
min-height : 100px; 
flex-direction : column; 
display : flex; 
align-self : center; 
width : 100%; 
height : 35%; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : linear-gradient(#f6b607 0%, #f6b607 100%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 32px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
__background-type : color; 
background-repeat : no-repeat; 
background-size : contain; 
background-position : center center; 
background-attachment : scroll; 
min-height : 66.39453125auto; 
width : 304px; 
height : 64px; 
align-self : center; 
border-radius : 30px 30px 30px 30px; 
border : 0 none black; 
margin : 4px 2px 4px 2px; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#i0n7fr {
padding : 10px; 
display : flex; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f2f2 0%, #f2f2f2 100%); 
align-items : center; 
height : 65%; 
width : 100%; 
} 
#ihdfug {
white-space-collapse : preserve; 
font-size : 24px; 
} 
#itg24k {
white-space-collapse : preserve; 
width : 10auto; 
text-align : left; 
font-weight : 100; 
} 
#igiten {
color : black; 
width : 339px; 
height : 220px; 
} 
#ic3u6r {
padding : 10px; 
min-height : 100px; 
width : 380px; 
display : flex; 
justify-content : center; 
} 
#iv0g0l {
padding : 10px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37oczi9.png'); 
} 
#i7x69l {
padding : 10px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37oczi9.png'); 
min-height : 39.27734375px; 
} 
#itedql {
__background-type : color; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : linear-gradient(#e6a43b 0%, #e6a43b 100%); 
width : 60%; 
height : 463px; 
display : flex; 
position : absolute; 
color : #ffffff; 
align-items : center; 
top : 3%; 
right : 54%; 
border-radius : 32px 32px 32px 32px; 
} 
#iozxpl {
padding : 10px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : none; 
width : 1034px; 
height : 603px; 
position : absolute; 
display : flex; 
align-items : flex-start; 
left : 48%; 
min-height : 11px; 
overflow : visible; 
} 
#ivyo6u {
padding : 10px; 
color : #ffffff; 
min-height : 470.52734375px; 
width : 100%; 
border : 0 solid black; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f8b80b 0%, #f8b80b 100%); 
border-radius : 34px 34px 34px 34px; 
font-family : "Lexend", serif; 
font-weight : 300; 
} 
.gjs-dropdown-menu-container {
position : relative; 
display : block; 
} 
.gjs-dropdown-menu-trigger {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
color : #ffffff; 
cursor : pointer; 
} 
.gjs-dropdown-menu-content {
position : absolute; 
width : 100%; 
min-height : 200px; 
box-shadow : rgba(0, 0, 0, 0.2) 0px 8px 16px 0px; 
z-index : 5; 
} 
.inputContainer {
width : 603px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
color : #ffffff; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-position-x : initial; 
background-position-y : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
color : #ffffff; 
} 
.standard_button.modificado {
__background-type : color; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : linear-gradient(#213a7c 0%, #213a7c 100%); 
width : 120px; 
height : 40px; 
border-radius : 15px 15px 15px 15px; 
} 
#i83rj4 {
width : 100%; 
display : flex; 
flex-direction : column; 
margin : 5% 0px 4% 0px; 
align-self : stretch; 
align-items : flex-end; 
} 
#ilgjxb {
padding : 10px; 
min-height : 100px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j9b6hx.jpg'); 
display : flex; 
justify-content : flex-end; 
align-items : center; 
} 
#ipjst4 {
min-height : 100px; 
__background-type : color; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#213a7c 0%, #213a7c 100%); 
} 
#in4gsc {
padding : 10px; 
min-height : 100px; 
width : 926px; 
display : flex; 
justify-content : space-between; 
align-items : center; 
} 
#iy0bu7 {
padding : 10px; 
display : block; 
color : #ffffff; 
min-height : 10auto; 
width : 568.438px; 
text-align : center; 
font-size : 32px; 
} 
#i137py {
color : black; 
width : 38px; 
height : 44px; 
} 
#i6s1k4 {
white-space-collapse : preserve; 
font-family : "Lexend", serif; 
} 
#iuyhhs {
padding : 10px; 
display : block; 
font-size : 20px; 
} 
#i987ss {
white-space-collapse : preserve; 
color : #ffffff; 
font-family : "Lexend", serif; 
} 
#i68lj2 {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 
#iru1nq {
color : black; 
width : 38px; 
height : 44px; 
} 
#iqu7o1 {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 
#ix8eqg {
color : black; 
width : 38px; 
height : 44px; 
} 
#i6irgm {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 
#i64dbt {
width : auto; 
text-decoration : none; 
} 
#i64dbt:hover  {
color : #2e709e; 
text-decoration : none; 
} 
#ibclo5 {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
font-size : 22px; 
} 
#i1xreu {
width : auto; 
} 
#i1xreu:hover  {
color : #2e709e; 
} 
#i28qdz {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
font-size : 22px; 
} 
#i0vg2v {
width : auto; 
} 
#i0vg2v:hover  {
color : #2e709e; 
} 
#idayty {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
font-size : 22px; 
} 
#io767h {
padding : 10px; 
min-height : 100px; 
width : 783px; 
display : none; 
flex-wrap : wrap; 
align-self : center; 
align-items : center; 
justify-content : space-around; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37lj913.png'); 
} 
#itdh6f {
white-space-collapse : preserve; 
} 
#iqj6i5 {
padding : 10px; 
display : block; 
color : #ffffff; 
min-height : 10auto; 
width : 568.438px; 
text-align : center; 
font-size : 32px; 
} 
#irya7h {
color : black; 
width : 38px; 
height : 44px; 
} 
#i9g4xj {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 
#i7gf77 {
color : black; 
width : 38px; 
height : 44px; 
} 
#i7qfbt {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 
#i9veu5 {
color : black; 
width : 38px; 
height : 44px; 
} 
#i1dymt {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 
#iklg6x {
width : 20.09%; 
min-height : 66px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#is16l4 {
padding : 10px; 
min-height : 100px; 
width : 926px; 
display : flex; 
justify-content : space-between; 
align-items : center; 
} 
#imqdmc {
min-height : 100px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37q2ixa.png'); 
display : none; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
#iwt1hr {
min-height : 100px; 
padding : 10px 0px 10px 10px; 
overflow : hidden; 
} 
#i29u1z {
min-height : 100px; 
width : 783px; 
display : flex; 
justify-content : center; 
} 
#idiuka {
width : 181.421875px; 
align-self : center; 
min-height : 10auto; 
} 
#i3sgq6 {
color : black; 
width : 339px; 
height : 220px; 
} 
#ily5z4 {
padding : 10px; 
min-height : 100px; 
width : 380px; 
display : flex; 
justify-content : center; 
} 
#ieb0gi {
color : black; 
width : 339px; 
height : 220px; 
} 
#i08g4i {
padding : 10px; 
min-height : 100px; 
width : 380px; 
display : flex; 
justify-content : center; 
} 
#itv317 {
font-size : 2rem; 
} 
#i88oyj {
white-space-collapse : preserve; 
color : #ffffff; 
font-size : 32px; 
font-weight : 700; 
} 
#i1qvp3 {
padding : 10px; 
display : block; 
} 
#is5k8f {
padding : 10px; 
min-height : 100px; 
width : 224px; 
display : none; 
align-items : center; 
} 
#iarvxe {
white-space-collapse : preserve; 
color : #ffffff; 
font-size : 32px; 
font-weight : 700; 
} 
#iobmtm {
padding : 10px; 
display : block; 
} 
#i5m3zb {
padding : 10px; 
min-height : 177px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37mwuw1.png'); 
} 
#i65r3w {
padding : 10px; 
min-height : 100px; 
width : 224px; 
display : flex; 
align-items : center; 
} 
#ifl9ok {
font-size : 2rem; 
} 
#it7n3g {
padding : 10px; 
min-height : 215px; 
width : 991px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left center; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37muh9n.png'); 
display : none; 
margin : 0px 0px 0px 0; 
} 
.standard_button.cotizaciones {
display : none; 
} 
#ilijmz {
padding : 10px; 
display : flex; 
width : 236px; 
justify-content : space-around; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#ipuecp {
min-height : 100px; 
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
align-items : stretch; 
} 
#ixg8or {
padding : 10px; 
width : 25%; 
} 
#itat2e {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
font-size : 25px; 
} 
#iypy3h {
display : block; 
} 
.imgDynamics {
width : 100px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#i503nx {
padding : 5px; 
width : 100%; 
height : auto; 
} 
#iusqje {
min-height : 100px; 
} 
#iqbpt8 {
padding : 0px 10px 0px 10px; 
color : #ffffff; 
} 
#ii6atx {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i5wlak {
background-color : transparent; 
} 
#ir6yms {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i4mwul {
background-color : transparent; 
} 
#i4vxf5 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#il6dm3 {
background-color : transparent; 
} 
#i3tb8i {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i1aexa {
background-color : transparent; 
} 
#ix08y7 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#ioq1a4 {
background-color : transparent; 
} 
#i91k7e {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i7c7rq {
background-color : transparent; 
} 
#iyflrx {
font-size : 18px; 
} 
#ihth4p {
min-height : 100px; 
display : flex; 
} 
#ics90s {
padding : 10px 10px 10px 0px; 
min-height : 100px; 
width : 50%; 
} 
#il8713 {
padding : 10px 0px 10px 10px; 
min-height : 100px; 
width : 50%; 
} 
#i4vebl {
padding : 10px 10px 10px 0px; 
min-height : 100px; 
width : 50%; 
} 
#ihaobl {
padding : 10px 0px 10px 10px; 
min-height : 100px; 
width : 50%; 
} 
#ie8nca {
min-height : 100px; 
display : flex; 
} 
.input {
width : 100%; 
color : #000000; 
height : 45px; 
padding : 10px 10px 10px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ebebeb 0%, #ebebeb 100%); 
border-radius : 15px 15px 15px 15px; 
} 
#ikldpb {
color : black; 
width : 708.28515625px; 
min-height : 10auto; 
z-index : 0; 
position : absolute; 
top : 27%; 
right : 10%; 
} 
#i3qhfy {
white-space-collapse : preserve; 
font-weight : 800; 
font-family : "Lexend", serif; 
color : #213a7c; 
} 
#io2s0j {
text-align : center; 
} 
#ih2mc {
font-family : "Lexend", serif; 
} 
#it1q5w {
white-space-collapse : preserve; 
font-weight : 800; 
font-family : "Lexend", serif; 
color : #213a7c; 
} 
#id1tkj {
padding : 10px; 
font-size : 35px; 
font-weight : 800; 
text-align : left; 
width : 566.38015625px; 
align-self : center; 
left : 2%; 
position : relative; 
color : #213a7c; 
font-family : "Lexend", serif; 
} 
#iovivl {
padding : 10px; 
min-height : 83px; 
display : flex; 
justify-content : space-between; 
margin : 0 0 1% 0; 
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j8zxo1.jpg'); 
border-radius : 30px 30px 30px 30px; 
font-weight : 800; 
font-family : "Lexend", serif; 
width : 100%; 
} 
#id9837 {
width : 100%; 
} 
#il9p63 {
text-align : left; 
} 
#i7uilt {
font-size : 32px; 
white-space-collapse : preserve; 
} 
#iv9tbg {
color : black; 
width : 38px; 
height : 44px; 
} 
#icd4wp {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 
#igd3of {
color : black; 
width : 38px; 
height : 44px; 
} 
#ia2da3 {
color : #d983a6; 
height : 44px; 
display : block; 
width : 38px; 
} 

  @media only screen and (max-width: 992px) {#ips2 {
padding : 10px 5px 10px 5px; 
}} 
@media only screen and (max-width: 992px) {#ic3u6r {
width : 320px; 
}} 
@media only screen and (max-width: 992px) {#iizaeu {
font-size : 32px; 
width : 97%; 
display : flex; 
min-height : 10auto; 
align-items : stretch; 
justify-content : center; 
}} 
@media only screen and (max-width: 992px) {#iyinfc {
font-size : 13px; 
width : 96.9%; 
justify-content : center; 
display : flex; 
}} 
@media only screen and (max-width: 992px) {#itmdc {
width : 362px; 
min-height : 40px; 
display : none; 
}} 
@media only screen and (max-width: 992px) {.links {
font-size : 12px; 
width : 127px; 
}} 
@media only screen and (max-width: 992px) {#ipu5h {
font-size : 12px; 
}} 
@media only screen and (max-width: 992px) {#i2g1w {
font-size : 12px; 
}} 
@media only screen and (max-width: 992px) {#ib1qi {
font-size : 12px; 
}} 
@media only screen and (max-width: 992px) {#ismeq {
margin : 0px 0px 0px 3%; 
width : 144.36340625px; 
}} 
@media only screen and (max-width: 992px) {#it2j54 {
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : local; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j6dm3e.jpg'); 
position : relative; 
min-height : 1000px; 
}} 
@media only screen and (max-width: 992px) {#i64dbt {
font-size : 12px; 
color : #ffffff; 
}} 
@media only screen and (max-width: 992px) {#i1xreu {
font-size : 12px; 
color : #ffffff; 
}} 
@media only screen and (max-width: 992px) {#i0vg2v {
font-size : 12px; 
color : #ffffff; 
}} 
@media only screen and (max-width: 992px) {#io767h {
width : 362px; 
min-height : 40px; 
display : flex; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : none; 
}} 
@media only screen and (max-width: 992px) {#itgbub {
width : 714px; 
height : 148px; 
font-size : 24px; 
}} 
@media only screen and (max-width: 992px) {#icxabj {
width : 100%; 
padding : 0px 0px 0px 0px; 
min-height : 216px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j7634m.jpg'); 
}} 
@media only screen and (max-width: 992px) {#io2s0j {
font-size : 24px; 
float : none; 
text-align : right; 
}} 
@media only screen and (max-width: 992px) {#i4n5wk {
width : 496.72265625px; 
min-height : 53px; 
font-size : 30px; 
text-align : left; 
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {#i6xx {
padding : 80px 0% 20px 0%; 
}} 
@media only screen and (max-width: 992px) {#i0n7fr {
justify-content : center; 
min-height : 10auto; 
height : 65%; 
}} 
@media only screen and (max-width: 992px) {.card {
width : 285px; 
height : 380px; 
min-height : 10px; 
flex-direction : column; 
}} 
@media only screen and (max-width: 992px) {#i5gjog {
min-height : 18px; 
margin : 0px 3% 0px 3%; 
}} 
@media only screen and (max-width: 992px) {#in4gsc {
width : 760px; 
}} 
@media only screen and (max-width: 992px) {#ihp5j {
width : 712px; 
}} 
@media only screen and (max-width: 992px) {#iozxpl {
width : 626px; 
height : 425px; 
left : 31%; 
top : 37%; 
}} 
@media only screen and (max-width: 992px) {#itedql {
width : 472px; 
min-height : 143px; 
right : 57%; 
border-radius : 10px 10px 10px 10px; 
top : -10%; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : none; 
}} 
@media only screen and (max-width: 992px) {.formInput {
width : 331px; 
}} 
@media only screen and (max-width: 992px) {.gjs-dropdown-menu-container {
width : 343px; 
min-height : 10px; 
height : 15%; 
display : flex; 
justify-content : flex-start; 
align-items : center; 
}} 
@media only screen and (max-width: 992px) {.inputContainer {
width : 100%; 
height : 15%; 
}} 
@media only screen and (max-width: 992px) {#ivyo6u {
font-size : 12px; 
min-height : 332.89453125px; 
display : block; 
width : 109.55%; 
}} 
@media only screen and (max-width: 992px) {.standard_button.modificado {
width : 119px; 
height : 36px; 
}} 
@media only screen and (max-width: 992px) {#i83rj4 {
min-height : 10px; 
}} 
@media only screen and (max-width: 992px) {#iy0bu7 {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {#ipjst4 {
width : 760px; 
display : none; 
}} 
@media only screen and (max-width: 992px) {#iqj6i5 {
font-size : 20px; 
width : 309.438px; 
}} 
@media only screen and (max-width: 992px) {#is16l4 {
width : 586px; 
font-family : "Lexend", serif; 
}} 
@media only screen and (max-width: 992px) {#imqdmc {
width : 760px; 
display : block; 
__background-type : color; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#213a7c 0%, #213a7c 100%); 
}} 
@media only screen and (max-width: 992px) {#iklg6x {
width : 171.062px; 
}} 
@media only screen and (max-width: 992px) {#iuyhhs {
font-size : 13px; 
}} 
@media only screen and (max-width: 992px) {#irya7h {
width : 25px; 
height : 29px; 
}} 
@media only screen and (max-width: 992px) {#i9g4xj {
width : 25px; 
height : 29px; 
}} 
@media only screen and (max-width: 992px) {#i7gf77 {
width : 25px; 
height : 29px; 
}} 
@media only screen and (max-width: 992px) {#i7qfbt {
height : 29px; 
width : 25px; 
}} 
@media only screen and (max-width: 992px) {#i9veu5 {
width : 25px; 
height : 29px; 
}} 
@media only screen and (max-width: 992px) {#i1dymt {
width : 25px; 
height : 29px; 
}} 
@media only screen and (max-width: 992px) {#ily5z4 {
width : 320px; 
display : none; 
}} 
@media only screen and (max-width: 992px) {#i08g4i {
width : 320px; 
}} 
@media only screen and (max-width: 992px) {#i29u1z {
justify-content : flex-end; 
width : 362px; 
min-height : 20px; 
}} 
@media only screen and (max-width: 992px) {#ihjr3o {
min-height : 701px; 
overflow : visible; 
}} 
@media only screen and (max-width: 992px) {#ijpl2e {
width : 533.625auto; 
text-align : center; 
font-size : 40px; 
font-family : "Lexend", serif; 
}} 
@media only screen and (max-width: 992px) {#i88oyj {
font-size : 24px; 
}} 
@media only screen and (max-width: 992px) {#i1qvp3 {
font-size : 24px; 
}} 
@media only screen and (max-width: 992px) {#is5k8f {
width : 673px; 
min-height : 53px; 
display : none; 
}} 
@media only screen and (max-width: 992px) {#iarvxe {
font-size : 24px; 
}} 
@media only screen and (max-width: 992px) {#iobmtm {
font-size : 24px; 
}} 
@media only screen and (max-width: 992px) {#i5m3zb {
min-height : 133px; 
}} 
@media only screen and (max-width: 992px) {#i65r3w {
width : 673px; 
min-height : 53px; 
}} 
@media only screen and (max-width: 992px) {#it7n3g {
width : 714px; 
height : 148px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37muh9n.png'); 
font-size : 24px; 
display : none; 
}} 
@media only screen and (max-width: 992px) {#ixg8or {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#itat2e {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#iwt1hr {
min-height : 168px; 
overflow : visible; 
}} 
@media only screen and (max-width: 992px) {#ikldpb {
width : 657.56640625px; 
min-height : 91.6328125px; 
top : 47%; 
}} 
@media only screen and (max-width: 992px) {#iir65o {
width : 667.42578125px; 
min-height : 25.3828125px; 
left : 51px; 
position : relative; 
}} 
@media only screen and (max-width: 992px) {#igqyi {
font-size : 26px; 
}} 
@media only screen and (max-width: 992px) {.standard_button {
font-size : 30px; 
}} 
@media only screen and (max-width: 992px) {#iovivl {
width : 663.94921875px; 
position : relative; 
}} 
@media only screen and (max-width: 992px) {#id1tkj {
font-size : 26px; 
}} 
@media only screen and (max-width: 992px) {#i93l3 {
width : 655.6328125px; 
font-size : 15px; 
}} 
@media only screen and (max-width: 992px) {#igd3of {
width : 25px; 
height : 29px; 
}} 
@media only screen and (max-width: 992px) {#ia2da3 {
width : 25px; 
height : 29px; 
}} 

  @media only screen and (max-width: 480px) {#itmdc {
display : none; 
}} 
@media only screen and (max-width: 480px) {#icq3pj {
padding : 10px; 
min-height : 67px; 
width : 81px; 
display : flex; 
align-items : flex-end; 
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#igqyi {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#i93l3 {
font-size : 9px; 
width : 269.30859375px; 
}} 
@media only screen and (max-width: 480px) {#io767h {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iozxpl {
__background-type : image; 
background-repeat : no-repeat; 
background-position : right center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37t51g0.png'); 
width : 310px; 
height : 555px; 
display : none; 
}} 
@media only screen and (max-width: 480px) {#ivyo6u {
width : 310px; 
}} 
@media only screen and (max-width: 480px) {#itedql {
width : 310px; 
right : 0%; 
}} 
@media only screen and (max-width: 480px) {#is16l4 {
width : 100%; 
height : 100%; 
min-height : 100%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#213a7c 0%, #213a7c 100%); 
}} 
@media only screen and (max-width: 480px) {#imqdmc {
width : 310px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37szznd.png'); 
min-height : 10px; 
}} 
@media only screen and (max-width: 480px) {.gjs-dropdown-menu-container {
width : 297px; 
}} 
@media only screen and (max-width: 480px) {.inputContainer {
width : 295px; 
}} 
@media only screen and (max-width: 480px) {.formInput {
width : 288px; 
}} 
@media only screen and (max-width: 480px) {#iqj6i5 {
font-size : 11px; 
width : 192.703px; 
}} 
@media only screen and (max-width: 480px) {#ic3u6r {
width : 242px; 
height : 178px; 
justify-content : center; 
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#igiten {
width : 274px; 
height : 178px; 
}} 
@media only screen and (max-width: 480px) {#ihp5j {
width : 310px; 
}} 
@media only screen and (max-width: 480px) {#i5gjog {
display : block; 
width : 95%; 
}} 
@media only screen and (max-width: 480px) {.standard_button {
width : 127.22265625px; 
font-size : 15px; 
min-height : 10auto; 
height : 36px; 
}} 
@media only screen and (max-width: 480px) {#iselx6 {
font-size : 9px; 
margin : 2px 1px 0px 6px; 
}} 
@media only screen and (max-width: 480px) {#i6xx {
padding : 20px 10px 20px 10px; 
display : flex; 
flex-direction : column; 
justify-content : space-around; 
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#itgbub {
width : 310px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left center; 
background-attachment : local; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim37muh9n.png'); 
min-height : 57px; 
position : relative; 
height : 100px; 
display : none; 
}} 
@media only screen and (max-width: 480px) {#i4n5wk {
width : 132px; 
min-height : 10px; 
align-items : center; 
align-self : center; 
display : none; 
}} 
@media only screen and (max-width: 480px) {#io2s0j {
width : 90.25px; 
min-height : 10auto; 
font-size : 12px; 
}} 
@media only screen and (max-width: 480px) {#i4j8vo {
min-height : auto; 
padding : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#icxabj {
min-height : 268px; 
display : none; 
flex-direction : column; 
}} 
@media only screen and (max-width: 480px) {#ijpl2e {
width : 10auto; 
text-align : left; 
font-size : 21px; 
}} 
@media only screen and (max-width: 480px) {#iyinfc {
width : 142.6328125px; 
font-size : 10px; 
justify-content : flex-start; 
}} 
@media only screen and (max-width: 480px) {#iizaeu {
width : 256px; 
font-size : 20px; 
justify-content : flex-start; 
}} 
@media only screen and (max-width: 480px) {.contentwrapperanimated {
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#iklg6x {
min-height : 20px; 
}} 
@media only screen and (max-width: 480px) {#iuyhhs {
font-size : 11px; 
text-align : right; 
}} 
@media only screen and (max-width: 480px) {#i3sgq6 {
width : 274px; 
height : 178px; 
}} 
@media only screen and (max-width: 480px) {#ily5z4 {
width : 242px; 
height : 178px; 
justify-content : center; 
align-items : center; 
display : none; 
}} 
@media only screen and (max-width: 480px) {#ieb0gi {
width : 274px; 
height : 178px; 
}} 
@media only screen and (max-width: 480px) {#i08g4i {
width : 242px; 
height : 178px; 
justify-content : center; 
align-items : center; 
display : none; 
}} 
@media only screen and (max-width: 480px) {#i29u1z {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iwt1hr {
min-height : 227px; 
padding : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#ihjr3o {
align-items : flex-start; 
justify-content : flex-end; 
min-height : 226px; 
align-self : flex-start; 
padding : 10px 10px 10px 10px; 
flex-direction : column; 
}} 
@media only screen and (max-width: 480px) {#it2j54 {
min-height : 364px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center bottom; 
background-attachment : local; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j6dm3e.jpg'); 
}} 
@media only screen and (max-width: 480px) {#ivz26q {
white-space-collapse : preserve; 
}} 
@media only screen and (max-width: 480px) {.standard_button.cotizaciones {
align-self : auto; 
display : block; 
width : 141.78515625px; 
min-height : 10auto; 
margin : 4px 2px 4px 2px; 
height : 37px; 
}} 
@media only screen and (max-width: 480px) {#ifq3sg {
padding : 0px 10px 10px 10px; 
min-height : 100px; 
__background-type : image; 
background-repeat : repeat; 
background-position : center top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404mwaim3j7634m.jpg'); 
}} 
@media only screen and (max-width: 480px) {#i88oyj {
font-size : 16px; 
color : #ebebeb; 
width : 10auto; 
}} 
@media only screen and (max-width: 480px) {#i1qvp3 {
width : 276.25px; 
min-height : 10auto; 
font-size : 12px; 
color : #ebebeb; 
text-align : center; 
font-family : "Lexend", serif; 
}} 
@media only screen and (max-width: 480px) {#is5k8f {
width : 100%; 
min-height : 10px; 
display : block; 
justify-content : center; 
padding : 0px 10px 10px 10px; 
}} 
@media only screen and (max-width: 480px) {#iarvxe {
font-size : 12px; 
}} 
@media only screen and (max-width: 480px) {#iobmtm {
width : 90.25px; 
min-height : 10auto; 
font-size : 12px; 
}} 
@media only screen and (max-width: 480px) {#i5m3zb {
min-height : 57px; 
width : 13px; 
}} 
@media only screen and (max-width: 480px) {#i65r3w {
width : 132px; 
min-height : 10px; 
align-items : center; 
align-self : center; 
display : none; 
}} 
@media only screen and (max-width: 480px) {#it7n3g {
width : 293px; 
position : relative; 
display : block; 
padding : 0px 0px 0px 0px; 
font-family : "Lexend", serif; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left center; 
background-attachment : scroll; 
background-size : contain; 
background-image : none; 
}} 
@media only screen and (max-width: 480px) {#ixg8or {
width : 100%; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#itat2e {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {#id1tkj {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#ismeq {
width : 105px; 
min-height : 42.57810625px; 
}} 
@media only screen and (max-width: 480px) {#iado6q {
font-family : "Lexend", serif; 
}} 
@media only screen and (max-width: 480px) {#iir65o {
width : 100%; 
padding : 0px 10px 0px 10px; 
min-height : 10px; 
left : 0px; 
position : static; 
}} 
@media only screen and (max-width: 480px) {#ihdfug {
font-size : 13px; 
}} 
@media only screen and (max-width: 480px) {#iovivl {
width : 275.55859375px; 
min-height : 10px; 
padding : 3px 10px 3px 10px; 
}} 
@media only screen and (max-width: 480px) {#ixg0cs {
min-height : 10auto; 
width : 94.15234375px; 
margin : -9px 0px 0px 0px; 
padding : 5px 5px 16px 5px; 
height : 28px; 
}} 
@media only screen and (max-width: 480px) {#iiipyl {
height : 17px; 
width : 20px; 
margin : 11px 0px 0px 17px; 
}} 
@media only screen and (max-width: 480px) {#iuu732 {
padding : 10px 10px 0px 10px; 
}} 
@media only screen and (max-width: 480px) {#itg24k {
width : 10auto; 
min-height : 10px; 
}} 
@media only screen and (max-width: 480px) {#ihia5p {
color : black; 
z-index : 2; 
left : 0px; 
width : 310px; 
margin : -19% 0px 0px 0px; 
min-height : 10px; 
display : block; 
}} 

}
  